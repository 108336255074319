<template>
  <div class="upload-tab d-flex">
    <!--ADD DRAGggggggggygg-->

    <!--UPLOADING DETAILS-->
    <div class="left-side pr-1">
      <UploadingDetails
        :session="session"
        :filteringErrMessage="filteringErrMessage"
        :startFilteringClicked="startFilteringClicked"
        :restartSessionClicked="restartSessionClicked"
        :lightSessionRestart="lightSessionRestart"
        :userActiveSourceRoutes="userActiveSourceRoutes"
      />

      <!--PROGRESS-->
      <UploadProgress
        v-if="isShowProgressBar"
        :filteringProgress="filteringProgress"
        :timer="timer"
        :session="session"
      />
    </div>

    <!--LEFT SIDE-->
    <div class="d-flex justify-center align-center right-side">
      <DropFiles
        v-if="levelOfUploading === 'drop'"
        :session="session"
        :isDrag="isDrag"
      />

      <!--TABLE AND FILES-->
      <div
        v-if="levelOfUploading === 'table'"
        class="d-flex flex-column fill-height fill-width"
      >
        <!--TABLE INFO / SESSION INFO-->
        <SessionInfo
          :session="session"
          :curFileUploaded="curFileUploaded"
          :sessionFiles="sessionFiles"
          :sessionSize="sessionSize"
          :cancelSessionClicked="cancelSessionClicked"
        />

        <!--FILES TABLE-->
        <UploadFilesTable
          :session="session"
          :curFileUploaded="curFileUploaded"
        />
      </div>
    </div>
    <!--MOBILE-->
  </div>
</template>

<script>
import { api } from '@/config'
import { mapGetters } from 'vuex'
import UploadingDetails from './Childrens/UploadingDetails/UploadingDetails.vue'
import UploadProgress from './Childrens/UploadProgress/UploadProgress.vue'
import DropFiles from './Childrens/DropFiles/DropFiles.vue'
import UploadFilesTable from './Childrens/UploadFilesTable/UploadFilesTable.vue'
import SessionInfo from './Childrens/SessionInfo/SessionInfo.vue'

//Website interface data
import {
  checkUploadingDetails,
  startObjForUpload,
  cancelUploading,
  startFiltering,
} from './Uploading'

export default {
  name: 'Uploading',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startObjForUpload)),
    }
  },
  props: {
    userActiveSourceRoutes: Array,
  },
  components: {
    UploadingDetails,
    UploadProgress,
    DropFiles,
    SessionInfo,
    UploadFilesTable,
  },
  watch: {
    session: {
      handler: function (n) {
        checkUploadingDetails(this, 'desktop')
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isInMiddleOfFiltering',
      // 'interactiveInterfaceFields',
    ]),

    isShowProgressBar() {
      if (this.isInMiddleOfFiltering) return true
      return false
    },

    levelOfUploading() {
      //if not filtering AND there are no files AND there is no report then show drop files
      if (
        !this.isInMiddleOfFiltering &&
        !this.session.filesData.length &&
        !this.statusResults.session.operationId
      )
        return 'drop'

      //if there are files and in middle of filtering
      if (this.session.filesData.length) return 'table'

      //if there is session report
      if (this.statusResults.session.operationId) return 'report'
    },
  },

  methods: {
    async startFilteringClicked() {
      let biggestFileSize = 0
      this.session.filesData.forEach(el =>
        el.file.numbSize > biggestFileSize
          ? (biggestFileSize = el.file.numbSize)
          : null
      )

      const isSessionApprovedRes = await api.get(
        `filter/sizepermissions?total=${this.session.totalSize}&biggest=${biggestFileSize}`
      )
      if (isSessionApprovedRes.status !== 200) return

      startFiltering(this, 'desktop')
    },

    async restartSessionClicked() {
      //if the user is individual and the session wansnt restarted
      if (
        this.loggedUser.role === 'Individual' &&
        !this.session.filesData.length
      )
        await deleteIndividualFiles(
          this.session.operationId,
          this.loggedUser.token
        )

      //this will restart the session
      Object.keys(startObjForUpload).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(startObjForUpload))[key]
      })
    },

    lightSessionRestart() {
      //this will run when a user did a session and
      //then changed the route, casuals, subject etc...
      if (!this.session.operationId) return

      this.session.htmlReport = {
        summary: '',
        date: '',
      }
      this.session.operationId = ''

      this.statusResults = {
        intact: 0,
        modified: 0,
        rejected: 0,
        total: 0,
        session: {},
        partial: 0,
      }
    },

    cancelSessionClicked() {
      cancelUploading(this)
    },
  },
}
</script>

<style scoped>
.upload-tab {
  width: 100%;
  height: 100%;
}

.w-8 {
  width: 8px;
}

.left-side {
  width: 35%;
  min-width: 300px;
}

.right-side {
  width: 65%;
}
</style>
