<template>
  <div id="route" :class="[type !== 'mobile' && 'pos-relative']">
    <v-select
      :attach="type !== 'mobile' && '#route'"
      v-model="session.selectedRoute"
      @change="routeChanged"
      :items="userActiveSourceRoutes"
      :error="errors.isRouteError"
      :disabled="isInMiddleOfFiltering"
      :label="$t('Route')"
      return-object
      outlined
      item-text="name"
      clearable
      dense
    >
      <template v-slot:selection="{ index, item }">
        <span v-if="index === 0" class="light-text fs-1-25r">{{
          item.name
        }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectRoute',
  props: {
    session: Object,
    userActiveSourceRoutes: Array,
    errors: Object,
    lightSessionRestart: Function,
    type: String,
  },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'loggedUser']),
  },
  methods: {
    routeChanged() {
      this.session.selectedUsers = []
      this.lightSessionRestart()
    },
  },
  created() {
    //if the org dosent have casuals,
    //then check if there is only one route
    //and if so then put it as selected route
    if (this.loggedUser.isOrgHasCasuals) return

    if (this.userActiveSourceRoutes.length === 1) {
      this.session.selectedRoute = this.userActiveSourceRoutes[0]
    }
  },
}
</script>

<style lang="scss">
#route {
  .v-menu__content {
    top: 41px !important;
    max-height: 160px !important;
  }
}
</style>
