<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    content-class="h-460 bg-white d-flex flex-column"
    width="460px"
    height="460px"
  >
    <div class="bg-white">
      <v-card-title class="text-h5 bg-grey font-weight-medium">
        {{ $t('Dynamic Fields') }}
      </v-card-title>
    </div>
    <div class="bg-white d-flex flex-column remaining-apace justify-space-between">
      <div class="remaining-space">
        <div
          class="d-flex"
          style="justify-content: space-between; align-items: center"
        >
          <ManageDynamicFields
            v-if="loggedUser.interfaceDynamicFields.length >= 1"
            :interfaceDynamicField="loggedUser.interfaceDynamicFields[0]"
            :fieldIndex="0"
            :isFieldOpen="isFirstFieldOpen"
            @delete-field="deleteField(0)"
            @set-is-field-open="v => (isFirstFieldOpen = v)"
            @close-other-fields="v => closeOtherFields(v)"
          />
          <v-tooltip top v-if="loggedUser.interfaceDynamicFields.length < 3">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="addField"
                v-on="on"
                class="mr-2 pointer small-btn-circle"
                dense
                fab
                color="primary"
                style="width: 24px !important; height: 24px !important"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <span>
              {{ $t('Add new field') }}
            </span>
          </v-tooltip>
        </div>
        <ManageDynamicFields
          v-if="loggedUser.interfaceDynamicFields.length >= 2"
          :interfaceDynamicField="loggedUser.interfaceDynamicFields[1]"
          :fieldIndex="1"
          :isFieldOpen="isSecondFieldOpen"
          @delete-field="deleteField(1)"
          @set-is-field-open="v => (isSecondFieldOpen = v)"
          @close-other-fields="v => closeOtherFields(v)"
        />
        <ManageDynamicFields
          v-if="loggedUser.interfaceDynamicFields.length >= 3"
          :interfaceDynamicField="loggedUser.interfaceDynamicFields[2]"
          :fieldIndex="2"
          :isFieldOpen="isThirdFieldOpen"
          @delete-field="deleteField(2)"
          @set-is-field-open="v => (isThirdFieldOpen = v)"
          @close-other-fields="v => closeOtherFields(v)"
        />
      </div>
      <div class="d-flex justify-end">

          <Button :disabled="isEqual" text="Save" :clickAction="updateDynamicFields" class="ma-2"  />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ManageDynamicFields from '@/components/CommonComponents/MangeDynamicFields/ManageDynamicFields.vue'
import { api } from '@/config'
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'DynamicFieldsDialog',
  data: () => ({
    freezedDynamicFields: '',
    dialog: true,
    isFirstFieldOpen: false,
    isSecondFieldOpen: false,
    isThirdFieldOpen: false,
  }),
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser']),
    isEqual(){
        return JSON.stringify(this.loggedUser.interfaceDynamicFields) === this.freezedDynamicFields
    }
  },
  props: {
    closeDialog: Function,
  },
  components: {
    ManageDynamicFields,
    Button,
  },
  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    deleteField(i) {
      this.loggedUser.interfaceDynamicFields.splice(i, 1)
    },
    addField() {
      if (this.loggedUser.interfaceDynamicFields.length < 3) {
        this.loggedUser.interfaceDynamicFields.push({
          TextToDisplay: '',
          Value: '',
          IsHierarchy: false,
          Required: false,
          Validation: 0,
        })
      }
    },
    closeOpenFields() {
      this.isFirstFieldOpen = false
      this.isSecondFieldOpen = false
      this.isThirdFieldOpen = false
    },
    closeOtherFields(fieldIndex) {
      switch (fieldIndex) {
        case 0:
          {
            this.isSecondFieldOpen = false
            this.isThirdFieldOpen = false
          }
          break
        case 1:
          {
            this.isFirstFieldOpen = false
            this.isThirdFieldOpen = false
          }
          break
        case 2:
          {
            this.isFirstFieldOpen = false
            this.isSecondFieldOpen = false
          }
          break
      }
    },
    async updateDynamicFields() {
      this.SET_PROCESSING(true)
      try {
        const res = await api.put(
          `casuals/${this.loggedUser.userId}/update-dynamic-fields`,
          this.loggedUser.interfaceDynamicFields
        )

        if (res.status !== 200)
          throw new Error('Error updating interface dynamic fields')
        this.loggedUser.interfaceDynamicField = res.data
        this.closeDialog()
        this.SET_NOTIFICATION({
            type: 'success',
            text: 'Dynamic Fields were changed successfully.',
          })
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
  mounted() {
    this.freezedDynamicFields = JSON.stringify(this.loggedUser.interfaceDynamicFields)
  }
}
</script>
