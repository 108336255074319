import { selectedHost } from '@/config'
import store from '@/store'
import {
  alertDialog,
  formatReport,
  isFolderNameValid,
  validateEmail,
  validateIsraelId,
  validatePhoneNumber,
} from '@/utils'
import dayjs from 'dayjs'

export function checkUploadingDetails(vue, type, isCheckOnly) {
  //this function check if uploading on tablet and mobile are fine
  //it gets the component as "this" and the type of the connection
  //if some field is not okay it returns
  //if everything is okay it returnes "true"
  const { loggedUser } = store.getters
  vue.errors.isPolicyError = false
  vue.errors.isRouteError = false
  vue.errors.isRegError = false
  vue.errors.isCasError = false
  vue.errors.isSubjectError = false

  //====================
  //if this is super admin or individual
  //====================
  if (loggedUser.organizationId === 0) {
    //if there is no policy
    if (!vue.session.policy) {
      if (!isCheckOnly) vue.errors.isPolicyError = true
      vue.filteringErrMessage = vue.$t('Policy is required')
      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)
      return
    }
  }

  //=======================================
  //if the user is registered / host admin
  //=======================================
  else {
    //if the send is regular
    if (vue.activeTab.tab === 'regular') {
      //if there is route and there are no users selected
      if (
        vue.session.selectedRoute?.routeId &&
        !vue.session.selectedUsers.length
      ) {
        if (!isCheckOnly) vue.errors.isRegError = true
        vue.filteringErrMessage = vue.$t(
          'At least one registered recipient is required'
        )
        if (type !== 'desktop' && !isCheckOnly)
          alertDialog(vue, vue.filteringErrMessage)

        return
      }

      //if there are no users casual or registered
      if (
        !vue.session.selectedUsers.length &&
        !vue.session.selectedCasualUsers.length
      ) {
        if (!isCheckOnly) {
          vue.errors.isCasError = true
          vue.errors.isRegError = true
        }

        vue.filteringErrMessage = vue.$t(
          'At least one registered recipient is required'
        )

        if (type !== 'desktop' && !isCheckOnly)
          alertDialog(vue, vue.filteringErrMessage)

        return
      }
    }

    //if the send is to casual
    else {
      //if there are no casual users selected
      if (!vue.session.selectedCasualUsers.length) {
        if (!isCheckOnly) {
          vue.errors.isCasError = true
        }

        vue.filteringErrMessage = vue.$t(
          'At least one guest recipient is required'
        )

        if (type !== 'desktop' && !isCheckOnly)
          alertDialog(vue, vue.filteringErrMessage)

        return
      }
    }
  }

  //====================
  //cases for all users
  //====================
  // check dynamic fields
  if (vue.session.interfaceDynamicFields) {
    for (let i = 0; i < vue.session.interfaceDynamicFields.length; i++) {
      if (
        vue.session.interfaceDynamicFields[i].required &&
        !vue.session.interfaceDynamicFields[i].value
      ) {
        vue.filteringErrMessage = `${
          vue.session.interfaceDynamicFields[i].textToDisplay
        } ${vue.$t('is required')}`
        return
      } else if (
        vue.session.interfaceDynamicFields[i].isHierarchy &&
        !isFolderNameValid(vue.session.interfaceDynamicFields[i].value)
      ) {
        vue.filteringErrMessage = `${vue.$t('The value of')} '${
          vue.session.interfaceDynamicFields[i].textToDisplay
        }' ${vue.$t('is invalid')}`
        return
      } else if (vue.session.interfaceDynamicFields[i].validation) {
        const v = vue.session.interfaceDynamicFields[i].value
        if (v) {
          switch (vue.session.interfaceDynamicFields[i].validation) {
            case 1: // email
              {
                if (!validateEmail(v && v.trim())) {
                  vue.filteringErrMessage = 'Email is not valid'
                  return
                }
              }
              break
            case 2: // phone number
              {
                if (v && !validatePhoneNumber(v.trim())) {
                  vue.filteringErrMessage = vue.$t('Mobile is not valid')
                  return
                }
              }
              break
            case 3: // israel id
              {
                if (!validateIsraelId(v && v.trim())) {
                  vue.filteringErrMessage = 'Invalid ID'
                  return
                }
              }
              break
          }
        }
      }
    }
  }
  //if there is subject and it's not valid
  // if (vue.session.subject && !isFolderNameValid(vue.session.subject)) {
  //   if (!isCheckOnly) vue.errors.isSubjectError = true
  //   if (vue.interactiveInterfaceFields.subjectFieldText === 'Subject')
  //     vue.filteringErrMessage = vue.$t('Subject is invalid')
  //   else vue.filteringErrMessage = vue.$t('Request number is invalid')
  //   if (type !== 'desktop' && !isCheckOnly)
  //     alertDialog(vue, vue.filteringErrMessage)

  //   return
  // }
  //if there are no files added
  if (vue.session.filesData.length === 0) {
    //on tablet alert a message
    if (type === 'tablet') {
      vue.filteringErrMessage = vue.$t('Files for filtering are missing')

      if (!isCheckOnly) return alertDialog(vue, vue.filteringErrMessage)

      return
    }

    //on desktop show a message
    if (type === 'desktop')
      return (vue.filteringErrMessage = vue.$t(
        'Files for filtering are missing'
      ))
  }

  //=====================
  //if everything is okay
  //=====================

  //on mobile
  if (type === 'mobile') return true
  //on tablet
  if (type === 'tablet') return true
  //on desktop
  if (type === 'desktop') return (vue.filteringErrMessage = '')
}

// export function checkUploadingDetails(vue, type, isCheckOnly) {
//   // Clear error flags
//   vue.errors = {
//     isPolicyError: false,
//     isRouteError: false,
//     isRegError: false,
//     isCasError: false,
//     isSubjectError: false,
//   }

//   const { loggedUser } = store.getters

//   // Check if uploading on tablet and mobile are fine
//   // by validating the uploading details
//   if (loggedUser.organizationId === 0) {
//     // Super admin or individual user
//     if (!vue.session.policy) {
//       if (!isCheckOnly) {
//         vue.errors.isPolicyError = true
//         vue.filteringErrMessage = vue.$t('Policy is required')
//         if (type !== 'desktop') {
//           alertDialog(vue, vue.filteringErrMessage)
//         }
//       }
//       return
//     }
//   } else if (loggedUser.role === 'Casual') {
//     // Casual user
//     if (!vue.session.selectedUsers.length) {
//       if (!isCheckOnly) {
//         vue.errors.isRegError = true
//         vue.filteringErrMessage = vue.$t(
//           'At least one registered recipient is required'
//         )
//         if (type !== 'desktop') {
//           alertDialog(vue, vue.filteringErrMessage)
//         }
//       }
//       return
//     } else if (!vue.session.subject) {
//       if (!isCheckOnly) {
//         vue.errors.isSubjectError = true
//         vue.filteringErrMessage = vue.$t('Subject is required')
//         if (type !== 'desktop') {
//           alertDialog(vue, vue.filteringErrMessage)
//         }
//       }
//       return
//     }
//   } else {
//     // Registered or host admin user
//     if (
//       vue.session.selectedRoute?.routeId &&
//       !vue.session.selectedUsers.length
//     ) {
//       if (!isCheckOnly) {
//         vue.errors.isRegError = true
//         vue.filteringErrMessage = vue.$t(
//           'At least one registered recipient is required'
//         )
//         if (type !== 'desktop') {
//           alertDialog(vue, vue.filteringErrMessage)
//         }
//       }
//       return
//     }

//     if (
//       !vue.session.selectedUsers.length &&
//       !vue.session.selectedCasualUsers.length
//     ) {
//       if (!isCheckOnly) {
//         vue.errors.isCasError = true
//         vue.errors.isRegError = true
//       }
//       if (loggedUser.isOrgHasCasuals) {
//         vue.filteringErrMessage = vue.$t(
//           'At least one registered or casual recipient is required'
//         )
//       } else {
//         vue.filteringErrMessage = vue.$t(
//           'At least one registered recipient is required'
//         )
//       }
//       if (type !== 'desktop') {
//         alertDialog(vue, vue.filteringErrMessage)
//       }
//       return
//     }
//   }

//   // Check if the subject is valid
//   if (vue.session.subject && !isFolderNameValid(vue.session.subject)) {
//     if (!isCheckOnly) {
//       vue.errors.isSubjectError = true
//       vue.filteringErrMessage = vue.$t('Subject is invalid')
//       if (type !== 'desktop') {
//         alertDialog(vue, vue.filteringErrMessage)
//       }
//     }
//     return
//   }

//   // Check if any files are added
//   if (vue.session.filesData.length === 0) {
//     if (type === 'tablet') {
//       vue.filteringErrMessage = vue.$t('Files for filtering are missing')
//       if (!isCheckOnly) {
//         alertDialog(vue, vue.filteringErrMessage)
//       }
//       return
//     } else if (type === 'desktop') {
//       vue.filteringErrMessage = vue.$t('Files for filtering are missing')
//       return
//     }
//   }

//   // Return true if everything is okay
//   if (type === 'mobile' || type === 'tablet') {
//     return true
//   } else if (type === 'desktop') {
//     vue.filteringErrMessage = ''
//     return
//   }
// }

export const uploadedFilesTableHeaders = [
  {
    text: 'Name',
    value: 'file.name',
    class: 'bg-table-heading table-header-first',
    width: '80%',
  },
  {
    text: 'Size',
    value: 'file.size',
    class: 'bg-table-heading ',
    width: '5%',
    align: 'center',
  },
  {
    text: 'Modified',
    value: 'file.lastModifiedDate',
    class: 'bg-table-heading ',
    width: '15%',
    align: 'center',
  },
  {
    value: 'action',
    name: 'delete',
    sortable: false,
    class: 'bg-table-heading table-header-last',
    width: '60px',
  },
]

export const startObjForUpload = {
  //=====BEFORE SESSION=====
  session: {
    policy: '',
    selectedUsers: [],
    selectedCasualUsers: [],
    selectedRoute: '',
    // interfaceDynamicFields: [],
    subject: '',
    message: '',
    filesData: [],
    totalSize: 0,
    htmlReport: {
      summary: '',
      date: '',
    },
    passwords: '',
    sizeOfUploadedData: 0,
    operationId: '',
  },
  activeTab: { tab: 'regular' },
  filteringErrMessage: '',
  freezedTotalSize: 0,
  startTime: '',
  errors: {
    isPolicyError: false,
    isRouteError: false,
    isRegError: false,
    isCasError: false,
    isSubjectError: false,
  },
  isDrag: false,

  //=====THROGH SESSION=====
  upload: null,
  timer: 0,
  curFileUploaded: null,
  sessionFiles: {
    total: 0,
    progress: 0,
  },
  sessionSize: {
    total: 0,
    progress: 0,
  },
  filteringProgress: {
    dataSent: 0,
    analyzedPercentage: 0,
    synthesizedPercentage: 0,
  },

  //=====AFTER SESSION=====
  statusResults: {
    intact: 0,
    modified: 0,
    rejected: 0,
    total: 0,
    session: {
      htmlReport: {
        summary: '',
        date: '',
      },
    },
    partial: 0,
  },
}

export function DownloadReport(htmlReport) {
  try {
    const date = dayjs().format('DD-MM-YYYY HH-mm-ss')
    //this will give the report a nice frame with the information about the sent data
    const report = formatReport(htmlReport)

    //create blob, create a tag and download throw it the files
    const blob = new Blob([report])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)

    link.setAttribute(
      'download',
      `Filtering Report ${date.replaceAll('/', '.').replaceAll(':', '-')}.html`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.log('error', error)
  }
}

export function downloadFilesIndividual(vue, operationId) {
  const { loggedUser } = store.getters
  const href = `${selectedHost}/api/filter/individual/download?guid=${operationId}&bearer=${loggedUser.token}`
  const date = dayjs().format('DD-MM-YYYY HH-mm-ss')

  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display: none',
    download: `dotEngines filtered files ${date}`,
  })

  document.body.appendChild(a)
  a.click()
  a.remove()

  store.commit('SET_NOTIFICATION', {
    type: 'success',
    text: 'Download started',
  })

  setTimeout(() => {
    vue.restartSessionClicked()
  }, 1500)
}

export function checkIfUserCanSeeProgress(vue) {
  const { role, userId } = store.getters.loggedUser
  //super admin can see
  if (role === 'SuperAdmin' || role === 'Individual') return true

  //casual cant see progress
  if (role === 'Casual') return false

  //if there is no selected route then don't show the progress
  if (!vue.session.selectedRoute) return false
  if (!vue.session.selectedRoute.routeId) return false

  //registered only if the is allowed in the ROUTE
  const userEndPoint = vue.session.selectedRoute?.sourceEndPoints.find(
    user => user.userId === userId
  )
  if (!userEndPoint.isShowInteractiveProgress) return false
  return true
}
