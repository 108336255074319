import router from '@/router'
import { api } from '@/config'

function removeTableItems() {
  const arr = ['org routes', 'users-list', 'logs table', 'hosts']

  for (let i of arr) {
    sessionStorage.removeItem(`${i}-table-headers`)
  }
}

function removeAlertsFromScreen() {
  const items = document.getElementsByClassName('dg-btn--cancel')
  if (items && items.length) items[0].click()
}

export default {
  state: {
    token: '',
    isShowLogin: true,
    user: {},
    isConfirmationNeeded: false,
    usrPass: '',
  },

  mutations: {
    SET_IS_SHOW_LOGIN(state, payload) {
      state.isShowLogin = payload
    },
    SET_ISCONFIRMATIONNEEDED(state, value) {
      state.isConfirmationNeeded = value
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_PASS(state, pass) {
      state.usrPass = pass
    },
    CLEAR_TOKEN(state) {
      state.token = ''
      sessionStorage.removeItem('token')
    },
  },
  actions: {
    async AUTH({ commit, dispatch }) {
      const user = JSON.parse(sessionStorage.getItem('user'))
      if (user) {
        try {
          api.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
          const res = await api.get(`users/${user.userId}`)
          // if (res.status === 401) {
          //   // sessionStorage.removeItem('user')
          //   router.push('/')
          // }
          if (res.status !== 200) throw Error
          commit('SET_USER', { ...res.data, token: user.token })
          commit('SET_TOKEN', user.token)
          // dispatch('GET_INTERACTIVE_INTERFACE_FIELDS_DATA')
        } catch (e) {
          console.log(e)
        }
      }
    },
    LOG_OUT({ commit }) {
      delete api.defaults.headers.common.Authorization
      delete api.defaults.headers.common.OrganizationId
      commit('CLEAR_TOKEN')
      commit('SET_USER', {})
      commit('SET_IS_SHOW_LOGIN', true)
      commit('SET_PASS', '')

      removeTableItems()
      sessionStorage.removeItem('user')

      removeAlertsFromScreen()

      if (router.currentRoute.path !== '/') router.push('/')
    },

    async LOGIN({ commit, dispatch }, payload) {
      removeTableItems()
      commit('SET_PROCESSING', true)
      try {
        let res = await api.post('auth/authenticate', payload)
        let userData = null

        //if error throw error
        if (res.status !== 200) {
          throw { response: res }
        }

        //well...
        commit('SET_PASS', payload.password)

        //if two step verfication is needed
        if (res.data.isTwoStepVerification) {
          commit('SET_ISCONFIRMATIONNEEDED', true)
          commit('SET_NOTIFICATION', {
            type: 'success',
            text: 'Success, please enter verification code',
          })
        }

        //if two step is not needed
        else {
          //if the user licenses is API or SFTP don't let him get in
          if (res.data.user.role !== 'Admin') {
            if (res.data.user.license === 'API') {
              commit('SET_NOTIFICATION', {
                type: 'error',
                text: `${res.data.user.license} license cannot login to website interface`,
              })
              throw Error
            }
          }

          userData = {
            ...res.data.user,
            role: res.data.user?.role,
            token: res.data.token,
            isConnectedToKiosk: res.data.isConnectedToKiosk,
          }

          //set logged user
          commit('SET_USER', {
            ...userData,
          })
          // dispatch('GET_INTERACTIVE_INTERFACE_FIELDS_DATA')

          //set the user at session storage for refreshing
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              ...userData,
            })
          )

          const token = userData.token
          //set the user token
          if (token) {
            commit('SET_TOKEN', token)
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`
            sessionStorage.setItem('token', token)
          }

          //get the orgaization theme
          await dispatch(
            'GET_THEME',
            { id: userData.organizationId },
            { root: true }
          )

          //if it is the users first time send him to new password page
          if (res.data.isFirstLogin) {
            return router.push({
              path: '/set-new-password',
              query: { key: token },
            })
          }

          //get the organization if this is the users organization
          if (userData.role === 'SuperAdmin' || userData.role === 'Admin')
            await dispatch('GET_SINGLE_ORGANIZATION', userData.organizationId)
          // if this is login into files
          if (payload.logToFiles) {
            router.push({
              path: `/${
                userData.license === 'SFTP' ? 'sftp' : 'website'
              }-interface`,
              query: { path: payload.logToFiles },
            })
          }
          //if it is not login into files
          else {
            if (userData.status !== 0) {
              router.push('/blocked')
            } else if (userData.role === 'SuperAdmin') {
              router.replace('/management')
            } else if (userData.role === 'Admin') {
              router.push(`/management/hosts/${userData?.organizationId}`)
            } else if (userData.role === 'Individual') {
              router.push('/website-interface')

              // if this regular user check if he have the option to interactive interface
            } else {
              const res = await api.get(`/routes/loggedUserRoutes`)

              const loggedUserRoutes = res.data

              // this will run and check if the user has the option of interactive
              let isHasInteractive = false
              for (let route of loggedUserRoutes) {
                for (let endPoint of route.sourceEndPoints) {
                  if (
                    endPoint.license === 'Website' &&
                    endPoint.userId === userData.userId
                  ) {
                    isHasInteractive = true
                    break
                  }
                }
                if (isHasInteractive === true) break
                for (let endPoint of route.targetEndPoints) {
                  if (
                    endPoint.license === 'Website' &&
                    endPoint.userId === userData.userId
                  ) {
                    isHasInteractive = true
                    break
                  }
                }
                if (isHasInteractive === true) break
              }

              if (userData.license === 'SFTP') router.push('/sftp-interface')
              else if (
                isHasInteractive ||
                userData.isConnectedToKiosk ||
                userData.isAllowCasuals
              ) {
                router.push('/website-interface')
              }

              // if the user dont have the option to interactive log him out
              else {
                commit('SET_NOTIFICATION', {
                  type: 'error',
                  text: 'There are no website routes or guest licence for this user yet',
                })
                dispatch('LOG_OUT')
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
    async CONFIRM_LOGIN({ commit, dispatch }, payload) {
      try {
        let res = await api.post('auth/VerifyTwoFactorCode', payload)
        if (res.status !== 200) {
          throw res
        }
        let userData = null
        userData = {
          ...res.data.user,
          role: res.data.user.role,
          token: res.data.token,
          isConnectedToKiosk: res.data.isConnectedToKiosk,
        }

        //only website can enter or host admin
        if (res.data.user.role !== 'Admin') {
          if (res.data.user.license === 'API') {
            commit('SET_NOTIFICATION', {
              type: 'error',
              text: `${res.data.user.license} license cannot login to website interface`,
            })
            throw Error
          }
        }

        //set currentUser
        commit('SET_USER', {
          ...userData,
        })
        // dispatch('GET_INTERACTIVE_INTERFACE_FIELDS_DATA')

        //set the user token
        const token = userData.token
        if (token) {
          commit('SET_TOKEN', token)
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`
          sessionStorage.setItem('token', token)
        }

        //get the orgaization theme
        await dispatch(
          'GET_THEME',
          { id: userData.organizationId },
          { root: true }
        )

        commit('SET_ISCONFIRMATIONNEEDED', false)

        //set the user at session storage for refreshing
        sessionStorage.setItem(
          'user',
          JSON.stringify({
            ...userData,
          })
        )

        //get the organization if this is the users organization
        if (userData.role === 'SuperAdmin' || userData.role === 'Admin') {
          await dispatch('GET_SINGLE_ORGANIZATION', userData.organizationId)
        }

        if (payload.logToFiles) {
          router.push({
            path: `/${
              userData.license === 'SFTP' ? 'website' : 'sftp'
            }-interface`,
            query: { path: payload.logToFiles },
          })
        } else {
          if (userData.status !== 0) {
            router.push('/blocked').catch(e => {
              console.log(e)
            })
          } else if (userData.role === 'SuperAdmin') {
            router.replace('/management')
          } else if (userData.role === 'Admin') {
            router.push(`/management/hosts/${userData?.organizationId}`)
          } else if (userData.role === 'Individual') {
            router.push('/website-interface')
          } else {
            const res = await api.get(`/routes/loggedUserRoutes`)

            const loggedUserRoutes = res.data
            // this will run and check if the user has the option of interactive
            let isHasInteractive = false
            for (let route of loggedUserRoutes) {
              for (let endPoint of route.sourceEndPoints) {
                if (
                  endPoint.license === 'Website' &&
                  endPoint.userId === userData.userId
                ) {
                  isHasInteractive = true
                  break
                }
              }
              if (isHasInteractive === true) break
              for (let endPoint of route.targetEndPoints) {
                if (
                  endPoint.license === 'Website' &&
                  endPoint.userId === userData.userId
                ) {
                  isHasInteractive = true
                  break
                }
              }
              if (isHasInteractive === true) break
            }

            if (
              isHasInteractive ||
              userData.isOrgHasCasuals ||
              userData.isConnectedToKiosk
            ) {
              if (userData.license === 'SFTP') router.push('/sftp-interface')
              else router.push('/website-interface')
            } else {
              commit('SET_NOTIFICATION', {
                type: 'error',
                text: 'There are no website routes for this user yet',
              })

              dispatch('LOG_OUT')
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async REGISTER({ commit }, payload) {
      commit('SET_PROCESSING', true)
      try {
        let res = await api.post('users/register', payload)
        if (res.status !== 200) {
          throw { response: res }
        }
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_PROCESSING', false)
      }
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    isShowLogin: state => state.isShowLogin,
    token: state => state.token,
    loggedUser: state => state.user,
    isConfirmationNeeded: state => state.isConfirmationNeeded,
    usrPass: state => state.usrPass,
  },
}
